import React, { useState } from 'react';
import MenuComponent from '../MenuComponent'; // Adjust the import based on your file structure

const Navbar = () => {
  // State to track which dropdown is active
  const [activeDropdown, setActiveDropdown] = useState(null);

  // Handle dropdown toggling
  const handleDropdownToggle = (dropdownId) => {
    setActiveDropdown(activeDropdown === dropdownId ? null : dropdownId);

    // Close the currently open dropdown if a new one is opened
    if (activeDropdown && activeDropdown !== dropdownId) {
      setActiveDropdown(null);
    }
  };

  const barstyle = {
    marginbottom: -0.5,
  };
  return (
    <div className="site-header-cont">
      <header className="site-header inner-header-login">
        <div className="container-fluid">
          <nav className="navbar navbar-expand-lg">
            <a className="navbar-brand" href="https://balady.gov.sa/">
              <img
                className="logo"
                alt="logo"
                src="https://apps.balady.gov.sa/BALADYCDN/Content//Images/logo-light.svg"
                style={{
                  height: 40,
                }}
              />
            </a>
            <header className="mainHeader">
              <div className="container-fluid">
                <div className="row">
                  <div className="col d-flex columns">
                    <div className="region region--header">
                      <div
                        id="block-sitebranding"
                        className="clearfix site-branding block block-system block-system-branding-block"
                      >
                        <a
                          href="https://balady.gov.sa/ar"
                          rel="home"
                          className="site-branding__logo"
                        >
                          <img
                            src="https://balady.gov.sa/themes/custom/balady_new/logo.svg"
                            alt="الرئيسية"
                          />
                        </a>
                      </div>
                    </div>
                    <nav className="navbar navbar-expand-lg position-static header-mobile-container">
                      <button
                        onClick={() => handleDropdownToggle('mainMenu')}
                        className="navbar-toggler"
                        type="button"
                        aria-controls="navbarSupportedContent"
                        aria-expanded={activeDropdown === 'mainMenu'}
                        aria-label="Toggle navigation"
                        data-toggle="collapse"
                        data-target="#navbarSupportedContent"
                      >
                        {/* Hamburger Menu Bars */}
                        <div className="bar"></div>
                        <div
                          className="bar"
                          style={{
                            margin: '4px 0 -0.5px 0',
                          }}
                        ></div>
                        <div className="bar"></div>
                      </button>

                      <div
                        className={`collapse navbar-collapse ${
                          window.innerWidth > 932 && 'show'
                        }  ${activeDropdown === 'mainMenu' ? 'show' : ''}`}
                        id="navbarSupportedContent"
                      >
                        <div className="region region--header-content">
                          <nav
                            aria-labelledby="block-balady-main-menu-menu"
                            id="block-balady-main-menu"
                            className="block block-menu navigation menu--main"
                          >
                            <h2
                              className="visually-hidden block-title"
                              id="block-balady-main-menu-menu"
                            >
                              القائمة الرئيسية
                            </h2>
                            <div className="block-content">
                              <MenuComponent />
                            </div>
                          </nav>
                        </div>
                      </div>
                    </nav>
                  </div>
                </div>
              </div>
            </header>
            {/* Header login */}
            <ul className="navbar-nav nav-setting-user">
              <li className="nav-item nav-item-notifications">
                <a
                  className="nav-link"
                  data-toggle="tooltip"
                  href="https://balady.gov.sa//Services/SiteMap"
                  data-placement="bottom"
                  title=""
                  data-original-title="جميع الخدمات"
                >
                  <svg
                    className="icon-back"
                    viewBox="0 0 24 24"
                    width={20}
                    height={20}
                  >
                    <use xlinkHref="#svg-boxs-ico" />
                  </svg>
                </a>
              </li>
            </ul>
            {/* End header login */}
          </nav>
        </div>
      </header>
    </div>
  );
};

export default Navbar;
